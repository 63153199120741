import React, { Component } from "react";

export class AccountSetup extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values, inputChange } = this.props;
    let errors = values.errors || {};
    console.log(values);
    return (
      <div className="form-container setup">
        <h1 className="mb-5">Saisir les infos du lead</h1>
        <div className="form-group">
          <label htmlFor="campagne">Campagne</label>
          <select
            name="campagne"
            className="form-control"
            onChange={inputChange("campagne")}
            value={values.campagne}
          >
            <option value="">Select</option>
            <option value="7jAb">Quintesens</option>
            <option value="zgQc">Pichet</option>
          </select>
          <span>{errors.campagne}</span>
        </div>
        <div className="form-group">
          <label htmlFor="civility">Civilité</label>
          <select
            name="civilite"
            className="form-control"
            onChange={inputChange("civilite")}
            value={values.civilite}
          >
            <option value="">Select</option>
            <option value="1">Monsieur</option>
            <option value="2">Madame</option>
          </select>
          <span>{errors.civilite}</span>
        </div>
        <div className="form-group">
          <label htmlFor="prenom">Prénom</label>
          <input
            type="text"
            className="form-control"
            name="prenom"
            onChange={inputChange("prenom")}
            value={values.prenom}
          />
          <span>{errors.prenom}</span>
        </div>
        <div className="form-group">
          <label htmlFor="nom">Nom</label>
          <input
            type="text"
            className="form-control"
            name="nom"
            onChange={inputChange("nom")}
            value={values.nom}
          />
          <span>{errors.nom}</span>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={inputChange("email")}
            value={values.email}
          />
          <span>{errors.email}</span>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Téléphone</label>
          <input
            type="number"
            className="form-control"
            name="telephone"
            onChange={inputChange("telephone")}
            value={values.telephone}
            placeholder="0609090999"
          />
          <span>{errors.telephone}</span>
        </div>
        <div className="form-group">
          <label htmlFor="address">Adresse</label>
          <input
            type="text"
            className="form-control"
            name="adresse"
            onChange={inputChange("adresse")}
            value={values.adresse}
          />
          <span>{errors.adresse}</span>
        </div>

        <div className="form-group">
          <label htmlFor="postal">Code Postal</label>
          <input
            type="number"
            className="form-control"
            name="code_postal"
            onChange={inputChange("code_postal")}
            value={values.code_postal}
            placeholder="64620"
          />
          <span>{errors.code_postal}</span>
        </div>
        <div className="form-group">
          <label htmlFor="city">Ville</label>
          <input
            type="text"
            className="form-control"
            name="ville"
            onChange={inputChange("ville")}
            value={values.ville}
          />
          <span>{errors.ville}</span>
        </div>
        <div className="form-group">
          <label htmlFor="imposition">Imposition</label>
          <select
            name="imposition"
            className="form-control"
            onChange={inputChange("imposition")}
            value={values.imposition}
          >
            <option value="">Select</option>
            <option value="1">Inférieur à 2 500€</option>
            <option value="2">Entre 2 500€ et 3 500€</option>
            <option value="2">Entre 3 000€ et 5 000€</option>
            <option value="3">Supérieur à 5 000€</option>
            <option value="3">Entre 5 000€ et 10 000€</option>
            <option value="4">Plus de 10 000€</option>
          </select>
          <span>{errors.imposition}</span>
        </div>
        <div className="form-group">
          <label htmlFor="imposition">Financement</label>
          <input
            type="text"
            className="form-control"
            name="financement"
            onChange={inputChange("financement")}
            value={values.financement}
          />
        </div>

        <br />

        <div className="text-right">
          <button className="btn btn-primary" onClick={this.continue}>
            Continue
          </button>
        </div>
      </div>
    );
  }
}

export default AccountSetup;
