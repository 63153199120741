export default function validateForm(values) {
  let errors = {};
  const {
    civilite,
    prenom,
    nom,
    email,
    telephone,
    adresse,
    code_postal,
    ville,
    imposition,
    campagne,
  } = values;
  if (!civilite.trim()) {
    errors.civilite = "Civilité obligatoire";
  }
  if (!campagne.trim()) {
    errors.civilite = "Campagne obligatoire";
  }
  if (!prenom.trim()) {
    errors.prenom = "Prénom obligatoire";
  }
  if (!nom.trim()) {
    errors.nom = "Nom obligatoire";
  }
  if (!email) {
    errors.email = "Email obligatoire";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = "Adresse Email invalide";
  }
  const postal = new RegExp(/^[0-9]{5}$/);
  if (!postal.test(code_postal.trim()))
    errors.code_postal = "Code postal non valide, il doit comporter 5 chiffres";
  if (!code_postal) {
    errors.code_postal = "Code postal obligatoire";
  }
  const phone = new RegExp(/^0[1-9]([\.|\-|\s]*[0-9]{2}){4}$/);
  if (!phone.test(telephone.trim()))
    errors.telephone = "Téléphone non valide, il doit comporter 10 chiffres";
  if (!telephone.trim()) {
    errors.telephone = "Téléphone obligatoire";
  }
  if (!adresse.trim()) {
    errors.adresse = "Adresse obligatoire";
  }
  if (!imposition.trim()) {
    errors.imposition = "Imposition obligatoire";
  }
  if (!ville.trim()) {
    errors.ville = "Ville obligatoire";
  }

  if (!Object.entries(errors).length) {
    errors = null;
  }
  return errors;
}
