import React, { Component } from "react";

export class Erreur extends Component {
  render() {
    const { results } = this.props;
    console.log(results);
    const message = results ? results.message : "";
    return (
      <div>
        <h1 className="text-white">Erreur survenue lors de l'envoi du Lead!</h1>
        <span>{message}</span> <br />
        <a href="/" className="btn btn-primary">
          Réessayer
        </a>
      </div>
    );
  }
}

export default Erreur;
