import React, { Component } from "react";

export class SocialProfiles extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, inputChange } = this.props;
    let errors = values.errors || {};

    return (
      <div className="form-container setup">
        <h1 className="mb-5">Code d'accès</h1>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            name="password"
            onChange={inputChange("password")}
            value={values.password}
          />
          <span>{errors.password}</span>
        </div>

        <br />

        <div className="row">
          <div className="col-6">
            <button className="btn btn-danger" onClick={this.back}>
              Back
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-primary" onClick={this.continue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialProfiles;
