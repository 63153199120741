import API from "./api";
const SendForm = async values => {
  const { step, password, errors, results, ...val } = values;
  try {
    const response = await API.post("api", val);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export default SendForm;
