import React, { Component } from "react";
import AccountSetup from "./AccountSetup";
import SocialProfiles from "./SocialProfiles";
import Confirm from "./Confirm";
import Success from "./Success";
import validateForm from "./validatorForm";
import validatorCode from "./validatorCode";
import SendForm from "./SendForm";
import Erreur from "./Erreur";
import Doublon from "./Doublon";
import ErreurExtension from "./ErreurExtension";

export class Form extends Component {
  state = {
    step: 1,
    civilite: "",
    prenom: "",
    nom: "",
    email: "",
    telephone: "",
    adresse: "",
    code_postal: "",
    ville: "",
    imposition: "",
    financement: "",
    campagne: "",
    password: "",
    source: "23",
    errors: {},
    results: {},
  };

  nextStep = async () => {
    const { step, ...val } = this.state;

    if (step === 1) {
      const form = validateForm(val);
      this.setState({ errors: form });
      !form && this.setState({ step: step + 1 });
    }
    if (step === 2) {
      const form = validatorCode(val);
      this.setState({ errors: form });
      !form && this.setState({ step: step + 1 });
    }
    if (step === 3) {
      const res = await SendForm(this.state);
      if (res) {
        const { data } = res;
        this.setState({ results: data });
      }
      this.setState({ step: step + 1 });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  inputChange = input => e => {
    this.setState({
      [input]: e.target.value,
    });
  };

  render() {
    const { step } = this.state;
    const {
      civilite,
      prenom,
      nom,
      email,
      telephone,
      adresse,
      code_postal,
      ville,
      imposition,
      financement,
      campagne,
      password,
      errors,
      results,
    } = this.state;
    const values = {
      civilite,
      prenom,
      nom,
      email,
      telephone,
      adresse,
      code_postal,
      ville,
      imposition,
      financement,
      campagne,
      password,
      errors,
      results,
    };

    switch (step) {
      case 1:
        return (
          <AccountSetup
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 2:
        return (
          <SocialProfiles
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 3:
        return (
          <Confirm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
          />
        );
      case 4:
        if (results && results.statut) {
          if (results.statut === "DBL") {
            return <Doublon />;
          } else if (results.statut === "KO") {
            return <Erreur results={results} />;
          } else if (results.statut === "OK") {
            return <Success />;
          } else if (results.statut === "NOK") {
            return <Erreur results={results} />;
          }
        } else {
          return <ErreurExtension />;
        }
      default:
        return (
          <AccountSetup
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
    }
  }
}

export default Form;
