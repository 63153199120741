import React, { Component } from "react";

export class Doublon extends Component {
  render() {
    return (
      <div>
        <h1 className="text-white">Lead déjà envoyer !</h1>
        <a href="/" className="btn btn-primary">
          Envoyer nouveau lead
        </a>
      </div>
    );
  }
}

export default Doublon;
