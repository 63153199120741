import React, { Component } from "react";

export class ErreurExtension extends Component {
  render() {
    return (
      <div>
        <h1 className="text-white">Erreur survenue lors de l'envoi du Lead!</h1>
        <h3 className="text-white">
          Assurez vous que l'extension ALLOW CORS est activé.
        </h3>
        <h3 className="text-white">
          Si vous ne l'avez pas installé sur votre navigateur, je vous invite à
          suivre ce
          <a
            href="https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf"
            target="_blank"
          >
            lien
          </a>
        </h3>
        <a href="/" className="btn btn-primary">
          Réessayer
        </a>
      </div>
    );
  }
}

export default ErreurExtension;
