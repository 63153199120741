export default function validateCode(values) {
  let errors = {};
  const { password } = values;

  if (!password.trim()) {
    errors.password = "Code d'accès obligatoire";
  }
  if (password.trim() && password.trim() !== "22392") {
    errors.password = "Code d'accès incorrect";
  }
  if (!Object.entries(errors).length) {
    errors = null;
  }
  return errors;
}
