import React, { Component } from "react";

export class Confirm extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: {
        civilite,
        prenom,
        nom,
        email,
        telephone,
        adresse,
        code_postal,
        ville,
        imposition,
        financement,
      },
    } = this.props;

    return (
      <div className="form-container">
        <h1 className="mb-5">Confirm</h1>
        <ul className="list-group">
          <li className="list-group-item">
            Civilité: <span className="confirm"> {civilite}</span>
          </li>
          <li className="list-group-item">
            Prénom:<span className="confirm"> {prenom}</span>
          </li>
          <li className="list-group-item">
            Nom: <span className="confirm">{nom}</span>
          </li>
          <li className="list-group-item">
            Email:<span className="confirm"> {email}</span>
          </li>
          <li className="list-group-item">
            Téléphone: <span className="confirm"> {telephone}</span>
          </li>
          <li className="list-group-item">
            Adresse: <span className="confirm"> {adresse}</span>
          </li>
          <li className="list-group-item">
            Code Postal: <span className="confirm">{code_postal}</span>
          </li>
          <li className="list-group-item">
            Ville:<span className="confirm"> {ville}</span>
          </li>
          <li className="list-group-item">
            Imposition:<span className="confirm"> {imposition}</span>
          </li>
          <li className="list-group-item">
            Source:<span className="confirm"> {financement}</span>
          </li>
        </ul>

        <br />
        <br />

        <div className="row">
          <div className="col-6">
            <button className="btn btn-danger" onClick={this.back}>
              Back
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-primary" onClick={this.continue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
